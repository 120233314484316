import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../axiosInstance';

export const fetchDriver = createAsyncThunk(
    'driver/fetchDriver',
    async function(phone) {
        
        const response = await axios.get(`https://api.diamondpay.am/api/driver`, {
            params: { phone }, 
            headers: {
                'Content-Type': 'application/json', 
            },
        });

        let data
        let active_park = localStorage.getItem('park');
        if(active_park === 'Alti'){
            active_park = 'AraratPark'
            localStorage.setItem('park', active_park);
        }
        if (active_park &&  response.data.drivers.find(driver => driver.park === active_park) !== -1) {
            data = response.data.drivers.find(driver => driver.park === active_park)
        }else{
            data = response.data.drivers[0];
        }
        return data;
    }
);

export const sendDriverSettings = createAsyncThunk(
    'driver/sendDriverSettings',
    async function({driver_id, percentage, hand_mode}) {
        
        const response = await axios.post(`https://api.diamondpay.am/api/driver`, 
            { 
                driver_id,
                percentage,
                hand_mode
            }, 
            {
                headers: {
                    'Content-Type': 'application/json', 
                },
                withCredentials: true  
            }
        );
        
        const data = response.data;
        console.log(data);
        return data;
    }
);

export const driverParks = createAsyncThunk(
    'driver/driverParks',
    async function() {
        const response = await axios.get(`https://api.diamondpay.am/api/driver/parks`);
        const data = response.data;
        return data;
    }
);