import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import './mainAdminApp.css';
import { selectMainLogin } from '../store/slices/mainAdminSlices/mainAdminLogin/mainAdminSlice';
import { mainLogin, mainParkList, mainParkReport } from '../store/slices/mainAdminSlices/mainAdminLogin/mainAdminLogin';
import getPercentage from "../assets/percentage";
import {CircleLoader, ClipLoader, DotLoader, GridLoader, RingLoader} from "react-spinners";

function MainAdminApp() {
    const mainData = useSelector(selectMainLogin);
    const dispatch = useDispatch();
    const [sum, setSum] = useState(0);
    const [daily, setDaily] = useState(0)
    const [loading, setLoading] = useState(false)// Countdown state for 10 seconds
    const [mainLoginInputs, setMainLoginInputs] = useState({
        username: 'admin',
        password: 'adminiparol'
    });


    const getToday = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(now.getDate()).padStart(2, '0'); // Current day of the month
        return `${year}-${month}-${day}`;
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMainLoginInputs({
            ...mainLoginInputs,
            [name]: value
        });
    };

    const handleMainLogin = (e) => {
        e.preventDefault();
        const { username, password } = mainLoginInputs;
        dispatch(mainLogin({ username, password }));
    };

    useEffect(() => {
        if (mainData.isLoggedIn) {
            dispatch(mainParkList());
        }
    }, [mainData.isLoggedIn, dispatch]);

    useEffect(() => {
        let newDaily = 0;
        let newSum = 0;

        // Wait for all asynchronous data fetching to complete
        for (let i = 0; i < mainData?.reportData?.length; i++) {
            let park = mainData.data.parks.find(el => el.park_id === mainData.reportData[i].park_id)
            newSum += getPercentage(park.name) / 100 * mainData.reportData[i].ok_total || 0;
            newDaily += getPercentage(park.name) / 100 * mainData.reportData[i].days[getToday()];
        }

        setSum(newSum);
        setDaily(() => newDaily);
    }, [mainData.reportData]);

    useEffect(() => {
        const fetchData = async () => {
            mainData.reportData.length || setLoading(true);

            console.log("mainData after update:", mainData);

            if (mainData.data?.parks?.length > 0) {
                // Wait for all dispatches to complete
                await Promise.all(
                    mainData.data.parks.slice(1).map((park) =>
                        dispatch(mainParkReport({ park_id: park.park_id }))
                    )
                );
            }



            setLoading(false); // End loading after data fetch
        };
        if(!mainData.reportData.length){
            fetchData()
        }
        const interval = setInterval(fetchData, 20000); // Continue fetching every 10 seconds

        return () => clearInterval(interval);
    }, [mainData,dispatch,]);



    const isPlus = () =>{
        return (Math.round((Math.round(sum) - Math.round(sum) / 20)) - 10400 - 2200 - 3500 - 10000) >=0
    }
    return (
        <div className="MainAdminApp">
            { !mainData.isLoggedIn && !loading? (
                <form onSubmit={handleMainLogin}>
                    <input
                        type="text"
                        name="username"
                        placeholder="Username"
                        value={mainLoginInputs.username}
                        onChange={handleInputChange}
                    />
                    <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={mainLoginInputs.password}
                        onChange={handleInputChange}
                    />
                    <button type="submit">Submit</button>
                </form>): (<></>)
            }

                {mainData?.data?.parks && mainData.reportData.length && !loading? (
                <div className="mainParkList">
                    <h1>{daily.toFixed(2)}</h1>
                    <div className="parkPercents">
                        <div className="parkInfoContainer">
                            <div className='parkInfo'>
                                <span>Name</span>
                                <span>Income</span>
                                <span>Active</span>
                                <span>%</span>
                            </div>
                            <hr/>
                            {mainData.reportData?.map((el, index) => (
                                <div className='parkInfo' key={index}>
                                    <span>{mainData.data?.parks?.find(park => park.park_id === el.park_id)?.name}</span>
                                    <span>֏{Math.round(getPercentage(mainData.data?.parks?.find(park => park.park_id === el.park_id)?.name) / 100 * el.ok_total)}</span>
                                    <span>{el.active_count}<i className="fas fa-bolt"></i></span>
                                    <span>{getPercentage(mainData.data?.parks?.find(park => park.park_id === el.park_id)?.name)}%</span>
                                </div>
                            ))}
                        </div>

                        <hr/>
                        <div className="infoContainer">
                            <div className="MoneyInfoContainer">
                                <div className='MoneyInfo impo'>
                                    <span>DiamondPay</span>
                                    <span>{Math.round(sum)}</span>
                                </div>
                                <div className='MoneyInfo'>
                                    <span>Tax 5%</span>
                                    <span>{Math.round(sum) / 20}</span>
                                </div>
                                <div className='MoneyInfo'>
                                    <span>Income</span>
                                    <span>{Math.round(sum) - Math.round(0.3 / 100 * sum) / 20}</span>
                                </div>
                                <div className={isPlus() ? 'MoneyInfo plus' : 'MoneyInfo impo'}>
                                    <span>Earnings</span>
                                    <span>{Math.round((Math.round(sum) - 13500))}</span>
                                </div>
                                <div className='MoneyInfo'>
                                    <span>All time</span>
                                    <span>{(Math.round(sum) + 127000)}</span>
                                </div>
                            </div>


                            {/*// ---------------------*/}


                            <div className="ParkContentInfoContainer">
                                {mainData.reportData?.map((el, index) => (
                                    <div className='MoneyInfo' key={index}>
                                        <span>{mainData.data?.parks?.find(park => park.park_id === el.park_id)?.name}</span>
                                        <span>{Math.round(getPercentage(mainData.data?.parks?.find(park => park.park_id === el.park_id)?.name) / 100 * el.days[getToday()])}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ) : loading && (
                    <div className="spinner-container">
                        <DotLoader size={100} color={"#FF5C00"} loading={loading}/>
                    </div>
                )}
        </div>
    );
}

export default MainAdminApp;
