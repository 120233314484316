import './AdminSettings.css';
import { Switch } from 'antd';
import {useEffect, useState} from 'react';
import {
    selectAdminLoading,
    selectAdminLogin,
    selectPasswordError
} from "../../../store/slices/adminSlices/adminLoginSlice/adminLoginSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    applySettings,
    changePassword,
    checkIsLoggedIn
} from "../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI";
import {toast} from "react-toastify";

const AdminSettings = () => {
    const adminLogin = useSelector(selectAdminLogin);
    const loading = useSelector(selectAdminLoading);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const passwordError = useSelector(selectPasswordError)
    const [settings, setSettings] = useState({
        bonus: adminLogin?.limit_mode,
    });
    const [activeCategory, setActiveCategory] = useState('payments');
    const [passwords, setPasswords] = useState({
        username: '',
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [hasChanges, setHasChanges] = useState(false);
    const onChangeBonus = (checked) => {
        setSettings((prevSettings) => ({
            ...prevSettings,
            bonus: checked,
        }));
    };

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPasswords((prev) => ({ ...prev, [name]: value }));
    };

    const handlePasswordSubmit = async () => {

        if (passwords.newPassword !== passwords.confirmPassword) {
            toast.error('Նոր գաղտնաբառերը չեն համընկնում։')
        } else {
            setPasswords({
                ...passwords,
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
            })
            await dispatch(changePassword({username: passwords.username, prev_password: passwords.currentPassword, new_password: passwords.newPassword}));
            if (!passwordError){
                toast.success('Գաղտնաբառը փոփոխված է։')
            }
            else{
                toast.error('Սխալ գաղտնաբառ')
            }
        }
    };
    const handleSubmitSettings = async () => {
            await dispatch(applySettings({limit_mode: settings.bonus})).then(() => {
                toast.success('Կարգաորումները պահպանված են')
                setHasChanges(false);
            })
                .catch((e) => {
                    toast.error(e.message)
                })
    }
    useEffect(() => {
        if(adminLogin?.limit_mode !== settings.bonus){
            setHasChanges(true);
        }
        else{
            setHasChanges(false);
        }
    }, [settings.bonus]);
    useEffect(() => {
        dispatch(checkIsLoggedIn());
    },[]);
    useEffect(() => {
        setSettings({
            ...settings,
            bonus: adminLogin?.limit_mode,
        },[]);
    }, [adminLogin]);
    useEffect(() => {
        if (!loading) {
            if (!adminLogin) {
                navigate('/admin/login');
            } else {
                setPasswords({...passwords, username: adminLogin.name});
                navigate('/admin/settings');
            }
        }
    }, [loading]);

    return (
        <div className="AdminSettings">
            <div className="AdminSettingsHeader">
                <h2>Կարգավորումներ</h2>

                <div className="AdminSettingsTabs">
                    <button
                        className={`AdminSettingsTab ${
                            activeCategory === 'payments' ? 'active' : ''
                        }`}
                        onClick={() => setActiveCategory('payments')}
                    >
                        Փոխանցումներ
                    </button>
                    <button
                        className={`AdminSettingsTab ${
                            activeCategory === 'security' ? 'active' : ''
                        }`}
                        onClick={() => setActiveCategory('security')}
                    >
                        Անվտանգություն
                    </button>
                </div>

                <div className="AdminSettingsContainer">
                    {/* Security Settings */}
                    {activeCategory === 'security' && (
                        <div className="AdminSettingsItem">
                            <h3>Անվտանգության կարգավորումներ</h3>
                            <div className="AdminSettingsItemContent">
                                <p>Փոփոխել գաղտնաբառը</p>
                            </div>
                            <div className="AdminSettingsItemContent">
                                <form className="ChangePasswordForm">
                                    <label>
                                        Ընթացիկ գաղտնաբառ
                                        <input
                                            type="password"
                                            name="currentPassword"
                                            value={passwords.currentPassword}
                                            onChange={handlePasswordChange}
                                        />
                                    </label>
                                    <label>
                                        Նոր գաղտնաբառ
                                        <input
                                            type="password"
                                            name="newPassword"
                                            value={passwords.newPassword}
                                            onChange={handlePasswordChange}
                                        />
                                    </label>
                                    <label>
                                        Կրկնել նոր գաղտնաբառը
                                        <input
                                            type="password"
                                            name="confirmPassword"
                                            value={passwords.confirmPassword}
                                            onChange={handlePasswordChange}
                                        />
                                    </label>
                                    <button
                                        type="button"
                                        onClick={handlePasswordSubmit}
                                        className="AdminSettingsApplyBtn"
                                    >
                                        Փոխել գաղտնաբառը
                                    </button>
                                </form>
                            </div>
                        </div>
                    )}

                    {/* Payments Settings */}
                    {activeCategory === 'payments' && (
                        <div className="AdminSettingsItem">
                            <h3>Բոնուսների կանխիկացման կանխում</h3>
                            <div className="AdminSettingsItemContent">
                                <p>
                                    Վարորդներին <b>արգելել</b> բոնուսների և Idram/Easypay/Tellcel ի
                                    կամ այլ միջոցներով փոխանցված գումարը կանխիկացնել
                                </p>
                                <Switch
                                    checked={settings.bonus}
                                    onChange={onChangeBonus}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {activeCategory !== 'security' && (
                <button onClick={handleSubmitSettings}
                        className={`AdminSettingsApplyBtn ${
                                    hasChanges ? 'unsaved' : ''
                }`}>Հաստատել</button>
            )}
        </div>
    );
};

export default AdminSettings;
