import { Link } from 'react-router-dom';
import './Settings.css'
import { useDispatch, useSelector } from 'react-redux';
import { selectDriver, startStopLoading } from '../../../store/slices/deiverSlice/deiverSlice';
import { driverParks, sendDriverSettings } from '../../../store/slices/deiverSlice/deiverAPI';
import { useEffect, useState } from 'react';
import { logout } from '../../../store/slices/loginInputSlice/loginInputAPI';
import { selectLanguage } from '../../../store/slices/languageSlice/LanguageSlice';
import { languageProvider } from '../../../assets/languageProvider';
import LanguageChange from '../../../sharedComponents/LanguageChange';
import { toast } from 'react-toastify';

const Settings = () => {
    const driver = useSelector(selectDriver);
    const dispatch = useDispatch();
    const activeLanguage = useSelector(selectLanguage);
    const language = languageProvider({page: 'settings', lang: activeLanguage});
    const [settings, setSettings] = useState({
        park: localStorage.getItem('park') || (driver?.settings?.park || "--"),
        hand_mode: driver?.settings?.mode === "auto" ? language.modeAuto : language.modeHand,
        percentage: driver?.settings?.percentage || 100
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'park') localStorage.setItem('park', value);
        setSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };

    const handleSubmit = () => {
        const driver_id = driver?.data?.driver_id; 
        const percentage = settings.percentage /100
        const hand_mode = settings.hand_mode === language.modeAuto ? true : false
        const park = driver.data.park_id
        
        dispatch(sendDriverSettings({ driver_id, percentage, hand_mode, park }));
        toast.success("Կարգավորումները թարմացված են",{
            autoClose: 2000,
            closeOnClick: true,
            draggable: true,
            });
        window.location.reload()
    };

    const handleLogout = () => {
        dispatch(logout());
        dispatch(startStopLoading(true));
        setTimeout(() => {
            window.location.reload();
        }, 100); 
    };
    useEffect(() => {
        dispatch(driverParks())
    }, []);
    return (
        <div className='Settings'>
            <div className="SettingsHeader">
                <Link to="/driver" className='HomeLink'><i className='fas fa-arrow-left'></i></Link>
                <span className='SettingsHeading'>{language.heading}</span>
            </div>
            <div className="MainSettings">
                <div className="Parks">
                    <div className="TransferSettingsHeadings">
                        <div className="SettingsIcon">
                            <i className="fa fa-taxi" aria-hidden="true"></i>
                        </div>
                        <span>{language.park}</span>
                    </div>
                    <select 
                        name="park" 
                        className='SettingsInputs' 
                        value={settings.park} 
                        onChange={handleChange}
                    >
                        {driver.parks?.map((park, index) => (
                            <option key={index} value={park.park}>{park.park}</option>
                        ))}
                    </select>
                </div>
                <div className="ModeChange">
                    <div className="TransferSettingsHeadings">
                        <div className="SettingsIcon">
                            <i className="fa fa-exchange" aria-hidden="true"></i>
                        </div>
                        <span>{language.mode}</span>
                    </div>
                    <select 
                        name="hand_mode" 
                        className='SettingsInputs' 
                        value={settings.hand_mode} 
                        onChange={handleChange}
                    >
                        <option value="Ավտոմատ">{language.modeAuto}</option>
                        {(driver?.data?.park_id !== '22f0784a005d4d28af14d93f24235c42' ||
                            driver?.data?.park_id !== '847fa7a317424a4ab70fdb6f1fdbb34e')
                            &&
                            <option value="Ձեռքով">{language.modeHand}</option>
                        }
                    </select>
                </div>
                {/* <div className="TransferPercent">
                    <div className="TransferSettingsHeadings">
                        <div className="SettingsIcon">
                            <i className="fa fa-percent" aria-hidden="true"></i>
                        </div>
                        <span>{language.percentage}</span>
                    </div>
                    <input 
                        type="number" 
                        name="percentage" 
                        value={settings.percentage}  
                        className='SettingsInputs' 
                        min='0' 
                        max='100' 
                        onChange={handleChange}
                    />
                </div> */}
                <div className="ApplySettings">
                    <button onClick={handleSubmit}>{language.apply}</button>
                </div>
            </div>
            <LanguageChange />
            <div className="DriverLogOut" onClick={handleLogout}>
                <i className="fa fa-sign-out" aria-hidden="true"></i>
                <span>{language.logout}</span>
            </div>
        </div>
    );
}

export default Settings;
