import './AdminBonus.css';
import { useDispatch, useSelector } from "react-redux";
import { selectAdminLoading, selectAdminLogin } from "../../../store/slices/adminSlices/adminLoginSlice/adminLoginSlice";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { checkIsLoggedIn } from "../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI";

const AdminBonus = () => {
    const dispatch = useDispatch();
    const adminLogin = useSelector(selectAdminLogin);
    const loading = useSelector(selectAdminLoading);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(checkIsLoggedIn());
    },[]);

    useEffect(() => {
        if (!loading) {
            if (!adminLogin) {
                navigate('/admin/login');
            } else {
                navigate('/admin/bonus-system');
            }
        }
    }, [loading, navigate]);

    // Predefined ball attributes
    const balls = [
        { size: 40, top: "10%", left: "15%", duration: "20s", delay: "0s", opacity: 0.7 },
        { size: 60, top: "25%", left: "55%", duration: "15s", delay: "1s", opacity: 0.6 },
        { size: 30, top: "40%", left: "30%", duration: "18s", delay: "2s", opacity: 0.8 },
        { size: 50, top: "60%", left: "70%", duration: "22s", delay: "3s", opacity: 0.5 },
        { size: 70, top: "80%", left: "20%", duration: "17s", delay: "0s", opacity: 0.4 },
        { size: 40, top: "15%", left: "80%", duration: "19s", delay: "2s", opacity: 0.9 },
        { size: 80, top: "50%", left: "10%", duration: "21s", delay: "4s", opacity: 0.3 },
        { size: 20, top: "65%", left: "35%", duration: "16s", delay: "1s", opacity: 0.7 },
        { size: 50, top: "75%", left: "60%", duration: "20s", delay: "3s", opacity: 0.6 },
        { size: 30, top: "30%", left: "40%", duration: "18s", delay: "2s", opacity: 0.5 },
    ];

    return (
        <div className="coming-soon-container">
            <div className="floating-balls">
                {balls.map((ball, index) => (
                    <div
                        key={index}
                        className="ball"
                        style={{
                            width: `${ball.size}px`,
                            height: `${ball.size}px`,
                            top: ball.top,
                            left: ball.left,
                            animationDuration: ball.duration,
                            animationDelay: ball.delay,
                            opacity: ball.opacity,
                        }}
                    ></div>
                ))}
            </div>
            <div className="content">
                <h1 className="coming-title">Շուտով</h1>
                <p className="coming-description">
                    Մենք աշխատում ենք այս բաժնի վրա՝ այն դարձնելով ավելի հետաքրքիր և օգտակար:
                </p>
            </div>
        </div>
    );
};

export default AdminBonus;
